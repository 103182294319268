.login {
	display: flex;
	flex-direction: column;
	align-content: center;
	padding: 100px 40px 0;
	min-height: 100vh;
	max-width: 400px;
	margin: 0 auto;
	background-color: inherit;

	&-header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 30px;
	}

	&-logo {
		width: 80px;
		height: 80px;
		background-color: var(--blueFFU);
		border-radius: 50%;
		padding: 15px;
		margin-bottom: 20px;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--pinkFFU);
		}
	}

	&-title {
		text-align: center;
		font-size: 25px;
		font-weight: 700;
	}

	&-formField {
		position: relative;
		display: flex;
		border-radius: 3px;
		transition:
			box-shadow 0.2s ease-in-out,
			border-color 0.2s ease-in-out;
		background-color: #fff;
		border: 1px solid #c2c8d0;

		label {
			position: absolute;
			left: 16px;
			color: #6f7780;
			background-color: #fff;
			z-index: 1;
			padding: 1px 6px;
			top: 50%;
			transform: translateY(-50%);
			transition:
				transform 0.15s ease-in-out,
				top 0.15s ease-in-out;
			transform-origin: 0;
			max-width: 90%;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			pointer-events: none;
			font-weight: 400;
		}

		&.error {
			border-color: var(--red);

			label {
				color: var(--red);
			}
		}

		&:focus,
		&:focus-within,
		&:focus-visible,
		&:-webkit-autofill,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:hover {
			outline: 0;
			border-color: #10a37f;
		}
	}

	&-form {
		margin: 0;
		display: flex;
		flex-direction: column;

		input {
			font-family: var(--poppins);
			border-style: solid;
			-webkit-appearance: none;
			font-size: 16px;
			height: 52px;
			line-height: 1.1;
			outline: none;
			box-shadow: none;
			padding: 0 16px;
			width: 100%;
			background-color: var(--white);
			transition: border-color 0.2s ease-in-out;
			border-radius: 3px;
			border: 1px solid var(--transparent);

			&.filled ~ label {
				top: -2px;
				transform: scale(0.88) translateX(-8px) translateY(-50%);
				overflow: visible;
				white-space: normal;
				color: #10a37f;
			}

			&:focus,
			&:focus-within,
			&:focus-visible,
			&:-webkit-autofill,
			&:-webkit-autofill:focus,
			&:-webkit-autofill:hover {
				~ label {
					top: -2px;
					transform: scale(0.88) translateX(-8px) translateY(-50%);
					overflow: visible;
					white-space: normal;
					color: #10a37f;
				}
			}
		}
	}

	&-formFieldButtonEye {
		pointer-events: none;
		display: block;
		width: 18px;
		height: 18px;
		background-image: url("data:image/svg+xml,%3Csvg fill='%23000000' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Ctitle%3Eeye%3C/title%3E%3Cpath d='M30.564 15.506c-9.59-10.957-19.543-10.957-29.129 0-0.115 0.131-0.185 0.305-0.185 0.494s0.070 0.363 0.186 0.495l-0.001-0.001c4.793 5.479 9.693 8.256 14.563 8.256h0.001c4.869 0 9.771-2.777 14.564-8.256 0.116-0.131 0.186-0.304 0.186-0.494s-0.070-0.363-0.187-0.495l0.001 0.001zM3.004 16c8.704-9.626 17.291-9.622 25.992 0-8.703 9.621-17.291 9.621-25.992 0zM16 11.25c-2.623 0-4.75 2.127-4.75 4.75s2.127 4.75 4.75 4.75c2.623 0 4.75-2.127 4.75-4.75v0c-0.003-2.622-2.128-4.747-4.75-4.75h-0zM16 19.25c-1.795 0-3.25-1.455-3.25-3.25s1.455-3.25 3.25-3.25c1.795 0 3.25 1.455 3.25 3.25v0c-0.002 1.794-1.456 3.248-3.25 3.25h-0z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
		mask-repeat: no-repeat;
		background-repeat: no-repeat;
		mask-size: 18px;
	}

	&-tooltip {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		position: absolute;
		text-align: center;
		font-size: 12px;
		background-color: var(--black);
		color: var(--white);
		padding: 8px;
		border-radius: 3px;
		left: -14px;
		bottom: 100%;
		transition:
			opacity 0.3s ease-in-out,
			visibility 0.3s ease-in-out;

		.login-tooltip {
			visibility: visible;
			opacity: 1;
		}

		span {
			display: block;
		}

		.show {
			display: block;
		}

		.hide {
			display: none;
		}

		&::after {
			content: '';
			position: absolute;
			top: 100%;
			transform: translateX(-50%);
			left: 50%;
			width: 0;
			height: 0;
			border-color: var(--black) transparent transparent;
			border-style: solid;
			border-width: 8px 8px 0;
		}
	}

	&-formFieldButton.cta.main {
		position: relative;
		background-color: var(--transparent);
		-webkit-appearance: none;
		border: none;
		height: 52px;
		width: 44px;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		border-radius: 0;

		.cta-content {
			pointer-events: none;
		}

		&.show {
			.login-formFieldButtonEye {
				background-image: url("data:image/svg+xml,%3Csvg fill='%23000000' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Ctitle%3Eeye-slash%3C/title%3E%3Cpath d='M18.822 22.904c-5.215 1.275-10.524-1.051-15.818-6.904 1.337-1.501 2.79-2.843 4.364-4.034l0.076-0.055c0.186-0.138 0.305-0.357 0.305-0.604 0-0.414-0.336-0.75-0.75-0.75-0.166 0-0.32 0.054-0.444 0.146l0.002-0.001c-1.91 1.447-3.588 3.024-5.086 4.761l-0.036 0.042c-0.115 0.131-0.185 0.305-0.185 0.494s0.070 0.363 0.186 0.495l-0.001-0.001c4.803 5.488 9.693 8.254 14.582 8.254 1.123-0.001 2.212-0.142 3.252-0.406l-0.092 0.020c0.332-0.082 0.573-0.377 0.573-0.729 0-0.414-0.336-0.75-0.75-0.75-0.064 0-0.125 0.008-0.184 0.023l0.005-0.001zM16.75 20c-0-0.414-0.336-0.75-0.75-0.75v0c-1.794-0.002-3.248-1.456-3.25-3.25v-0c0-0.414-0.336-0.75-0.75-0.75s-0.75 0.336-0.75 0.75v0c0.003 2.622 2.128 4.747 4.75 4.75h0c0.414-0 0.75-0.336 0.75-0.75v0zM23.565 22.503c2.701-1.672 5.010-3.665 6.965-5.967l0.034-0.042c0.116-0.131 0.186-0.304 0.186-0.494s-0.070-0.363-0.187-0.495l0.001 0.001c-6.844-7.82-13.822-10.081-20.758-6.76l-7.277-7.276c-0.135-0.131-0.32-0.212-0.523-0.212-0.414 0-0.75 0.336-0.75 0.75 0 0.203 0.081 0.388 0.213 0.523l27.999 28.001c0.136 0.136 0.324 0.22 0.531 0.22 0.415 0 0.751-0.336 0.751-0.751 0-0.207-0.084-0.395-0.22-0.531v0zM28.996 16c-1.852 2.121-4.004 3.919-6.402 5.345l-0.121 0.067-2.636-2.635c0.569-0.767 0.911-1.731 0.912-2.776v-0c-0.003-2.622-2.128-4.747-4.75-4.75h-0c-1.045 0.002-2.009 0.344-2.789 0.921l0.013-0.009-2.29-2.29c6.027-2.647 11.95-0.64 18.062 6.127zM14.301 13.239c0.486-0.307 1.077-0.489 1.711-0.489 1.788 0 3.238 1.45 3.238 3.238 0 0.634-0.182 1.225-0.497 1.724l0.008-0.013z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
			}

			.login-tooltip {
				.show {
					display: none;
				}

				.hide {
					display: block;
				}
			}
		}

		&:focus,
		&:focus-within,
		&:focus-visible,
		&:hover {
			background-color: rgb(0 123 173 / 15%);
			box-shadow: none !important;
			outline: none;
			background-color: var(--gray);

			.login-tooltip {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&-formFieldError {
		height: 24px;
		color: var(--red);
		font-size: 12px;
		text-align: right;
	}

	.login-formSubmit {
		background-color: #10a37f;
		color: var(--white);
		font-family: var(--poppins);
		font-weight: 300;
		border-style: solid;
		-webkit-appearance: none;
		font-size: 16px;
		height: 52px;
		line-height: 1.1;
		padding: 0 16px;
		width: 100%;
		transition: border-color 0.2s ease-in-out;
		border-radius: 3px;
		border: 1px solid #10a37f;
		margin-bottom: 15px;

		&:focus-visible {
			border-color: var(--focusBorderColor);
			outline: 0;
			box-shadow: var(--boxShadowFocusColor);
		}

		.loader {
			border-top: 3px solid var(--white);
		}
	}

	&-forgot {
		font-size: 14px;
		color: #10a37f;
		text-decoration: none;
		margin-bottom: 25px;
		align-self: flex-start;
		font-weight: 400;
		padding-left: 5px;
	}

	&-formWarning {
		text-align: center;
		font-weight: 400;

		&.error {
			color: var(--red);
		}
	}
}

@media (--media-desktop) {
	.login {
		&-logo {
			margin-bottom: 60px;
		}
		.login-formSubmit {
			&:hover {
				background-color: #0b7a63;
			}
		}
	}
}
