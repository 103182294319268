* {
	box-sizing: border-box;
	margin-left: 0;
	margin-right: 0;
	-webkit-overflow-scrolling: touch;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

html {
	scroll-behavior: smooth;
}

body {
	position: relative;
	font-family: var(--poppins);
	font-weight: 300;
	font-size: 16px;
	min-height: 100vh;
	color: var(--black);
	line-height: 1.5;
	background-color: var(--backgroundGray);
}

body.login-layout {
	background-color: var(--white);
}

.content {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.overlay {
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	background-color: rgb(0 0 0 / 70%);
	transition:
		opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1),
		visibility 0.3s;
	opacity: 0;
	visibility: hidden;
	transition-delay: 0.5s;
	z-index: 1;

	&.active {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.1s;
	}
}

h1,
h2 {
	margin: 0;
}

b {
	font-weight: 600;
}

img {
	max-width: 100%;
}

a {
	text-decoration: none;
}

button.datepicker-save {
	background-color: var(--success);
	color: var(--white);

	&:hover {
		background-color: var(--success);
		color: var(--white);
	}
}

@media (--media-desktop) {
	input,
	textarea,
	button,
	select,
	a {
		&:focus-visible {
			border-color: var(--focusBorderColor);
			outline: 0;
			box-shadow: var(--boxShadowFocusColor);
		}
	}

	.content {
		display: block;
	}

	main {
		padding: 20px 0 0 var(--menuSideBarWidth);
	}

	.overlay {
		display: none;
	}
}
