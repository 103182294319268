.notiflix {
	&-confirm-content {
		border-radius: 8px !important;
		font-family: var(--poppins) !important;

		#NXConfirmValidationInput {
			border-radius: 5px !important;
		}
	}
}
