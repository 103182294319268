:root {
	--containerMaxWidth: 1100px;
	--smallcontainerMaxWidth: 600px;
	--menuSideBarWidth: 280px;
	--headerHeight: 56px;

	/* fonts */
	--futura: futurapt-bold, sans-serif;
	--poppins: poppins, sans-serif;

	/* colors */
	--white: #fff;
	--white50: rgb(255 255 255 / 50%);
	--white80: rgb(255 255 255 / 80%);
	--lightWhite: #f9f9f9;
	--black: #414042;
	--red: #ea0606;
	--green: #82d616;
	--gray: #f2f2f2;
	--backgroundGray: #f8f9fa;
	--graySecondary: #d2d6da;
	--gray40: rgb(2 2 2 /40%);
	--gray60: rgb(2 2 2 /60%);
	--darkGray: #808080;
	--orange: #ffdead;
	--transparent: rgb(0 0 0 / 0%);
	--textColor: #8392ab;
	--darkBlue: #344767;
	--success: #82d616;
	--warning: #fbcf33;
	--info: #0ca5c2;

	/* blue */
	--blueFFU: #190f41;
	--blue50: #423c5a;
	--blue40: #716d83;
	--blue30: #a19dad;
	--blue20: #d0ced6;
	--blue10: #ecebef;

	/* pink */
	--pinkFFU: #e5c1b6;
	--pink50: #eacdc5;
	--pink40: #eed7d1;
	--pink30: #f2e1dc;
	--pink20: #f7ebe8;
	--pink10: #fbf5f3;

	/* gradients */
	--gradientDefault: linear-gradient(310deg, #627594, #a8b8d8);
	--gradientGreen: linear-gradient(310deg, #00d900, #c6ebbf);
	--gradientOrange: linear-gradient(310deg, #f53939, #fbcf33);
	--gradientBlack: linear-gradient(310deg, #000, #8f8c87);
	--gradientTurquoise: linear-gradient(310deg, #0c9ccb, #0ccb97);
	--gradientRed: linear-gradient(310deg, #ea0606, #ff667c);
	--gradientViolet: linear-gradient(310deg, #7928ca, #ff0080);

	/* shadow */
	--boxShadow: rgb(0 0 0 / 12%) 0px 2px 20px 0px;
	--lightShadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);

	/* focus */
	--focusBorderColor: #80bdff;
	--boxShadowFocusColor: 0 0 0 0.2rem rgb(0 123 255 / 25%);

	/* Transitions */
	--cubicBezier: cubic-bezier(0.83, 0, 0.17, 1);
}

/* Navbar breakpoint */
@custom-media --media-navbar only screen and (max-width: 1024px);

/* Breakpoints */
@custom-media --media-mobile only screen and (max-width: 749px);
@custom-media --media-tablet only screen and (min-width: 750px);
@custom-media --media-desktop only screen and (min-width: 1024px);
@custom-media --media-desktop-large only screen and (min-width: 1200px);
