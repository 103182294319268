.cta {
	color: var(--black);

	&:not(.simpleLink) {
		position: relative;
		color: var(--white);
		font-family: var(--poppins);
		font-weight: 300;
		-webkit-appearance: none;
		font-size: 16px;
		height: 52px;
		line-height: 1.1;
		padding: 0 16px;
		width: 100%;
		transition: border-color 0.2s ease-in-out;
		border-radius: 3px;
		margin-bottom: 15px;
	}

	&.main {
		transition:
			background-color 0.3s ease,
			color 0.3s ease;

		&::before {
			content: '';
			position: absolute;
			width: 6px;
			height: 6px;
			border-top: 2px solid var(--black);
			border-right: 2px solid var(--black);
			transform: translateY(-50%) rotate(45deg);
			top: 50%;
			right: 15px;
			transition:
				color 0.3s,
				opacity 0.3s;
			opacity: 0;
		}
	}

	&.disabled {
		cursor: not-allowed;
	}

	&-content {
		visibility: visible;
		opacity: 1;
		transition:
			visibility 0.3s,
			opacity 0.3s,
			transform 0.3s ease;
	}

	&-loader {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		place-items: center;
		visibility: hidden;
		opacity: 0;
		transition:
			visibility 0.3s,
			opacity 0.3s;
	}

	&.loading {
		pointer-events: none;

		.cta-loader {
			display: grid;
			opacity: 1;
			visibility: visible;
		}

		.cta-content {
			visibility: hidden;
			opacity: 0;
		}
	}
}

@media (--media-desktop) {
	.cta {
		&:not(.simpleLink) {
			font-size: 15px;
		}

		&.main {
			&:hover {
				/* background-color: var(--black); */
				/* color: var(--white); */

				/* .cta-content {
					transform: translateX(-10px);
				} */

				/* &::before {
					opacity: 1;
					border-top-color: var(--white);
					border-right-color: var(--white);
				} */
			}
		}
	}
}
